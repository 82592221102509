@font-face {
    font-family: 'DINNext';
    src: url('DINNextLTPro-Light.woff2') format('woff2'),
        url('DINNextLTPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINNext';
    src: url('DINNextLTPro-Black.woff2') format('woff2'),
        url('DINNextLTPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINNext';
    src: url('DINNextLTPro-MediumCond.woff2') format('woff2'),
        url('DINNextLTPro-MediumCond.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINNext';
    src: url('DINNextLTPro-Regular.woff2') format('woff2'),
        url('DINNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

